import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <h1>Privacy Policy</h1>
        {/* <p>Last updated: October 24, 2024</p> */}
      </header>

      <section>
        <h2>Overview</h2>
        <p>
          This Privacy Policy governs the manner in which Zipreel Android app ("we," "our," or "us") collects, uses,
          maintains, and discloses information collected from users ("you" or "user") of the Zipreel Android app, a video
          streaming platform.
        </p>
      </section>

      {/* <section>
        <h2>1. Information We Collect</h2>
        <h3>1.1 Personal Information</h3>
        <p>
          We may collect personal identification information from users in various ways, including but not limited to
          when users register on the app, subscribe to the service, and in connection with other activities, services,
          features, or resources we make available.
        </p>

        <h3>1.2 Usage Information</h3>
        <p>
          We may also collect non-personal identification information about users whenever they interact with our app.
          This may include the type of device, operating system, browser, and other technical information.
        </p>
      </section>

      <section>
        <h2>2. How We Use Collected Information</h2>
        <h3>2.1 Personalization</h3>
        <p>
          We may use the information we collect to personalize the user experience, such as recommending content based on
          user preferences.
        </p>

        <h3>2.2 Improve Services</h3>
        <p>
          We use collected information to improve our video streaming services, enhance user interface, and analyze app
          usage patterns.
        </p>

        <h3>2.3 Communication</h3>
        <p>
          We may use user email addresses to send updates, newsletters, and promotional materials related to our services.
        </p>
      </section>

      <section>
        <h2>3. Subscription Plans and Payments</h2>
        <h3>3.1 Subscription Plans</h3>
        <p>
          We offer two subscription plans - Basic Plan and Pro Plan. Each plan includes specified uploading capacity and
          is subject to a monthly subscription fee.
        </p>

        <h3>3.2 Payment Information</h3>
        <p>
          To process payments, we may collect payment information, including credit card details. All payment
          transactions are securely processed through a third-party payment gateway.
        </p>
      </section> */}

      <section>
        <h2>1. Security of Information</h2>
        <p>
          We adopt industry-standard security measures to protect against unauthorized access, alteration, disclosure, or
          destruction of personal information, including encryption of sensitive data.
        </p>
      </section>

      <section>
        <h2>2. Changes to This Privacy Policy</h2>
        <p>
          We reserve the right to update or modify this privacy policy at any time. The most recent version will be posted
          on our website, and the date of the latest revision will be indicated at the top of the page.
        </p>
      </section>

      <section>
        <h2>3. Contact Information</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at 6397112122 and Email Id anitamaam123@gmail.com.</p>
      </section>

      <footer>
        <p>Thank you for using the Zipreel Android app.</p>
      </footer>
    </div>
  );
}

export default App;
